import { ObjectKeyValue } from "../models/paymentState";

export const getTranslatePayments = (value: string) => {
  let status: ObjectKeyValue = {
    Pending: "Pendiente",
    Succeeded: "Exitoso",
    Failed: "Fallido",
    Cancelled: "Cancelado",
    Created: "Creado",
  };

  return status[value];
};

export const paymentsMethods: ObjectKeyValue = {
  credit_card: "Tarjeta de crédito",
  pse: "Pse",
  bank_transfer: "Transferencia bancaria",
  bancolombia_collect: "Pago en efectivo",
  cash: "Efectivo",
  bank_account_bancolombia: "Bancolombia",
  nequi: "Nequi",
  nequi_token: "Nequi",
};

export const getPaymentsMethod = (value: string) => {
  const label = paymentsMethods[value];
  if (label) {
    return label;
  }
  return value;
};

export const getTypeStatusPayments = (value: string) => {
  let status: ObjectKeyValue = {
    Pending: "warning",
    Succeeded: "success",
    Failed: "error",
    Cancelled: "error",
    Created: "info",
  };

  return status[value];
};

import {
  AdapterDayjs,
  Box,
  Button,
  DatePicker,
  Dayjs,
  dayjs,
  Grid,
  InputAdornment,
  LocalizationProvider,
  SearchIcon,
  TextField,
} from "@enerbit/base";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPayments,
  params,
  setParams,
} from "../../../../features/payment/payment";
import { ParamFilters, PropsPayments } from "../../../../models/paymentState";
import { AppDispatch } from "../../../../store/store";

const FilterSelect = ({ paymerId }: PropsPayments) => {
  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector(params);

  const changeParams = (value: ParamFilters) => {
    let values = { ...filters, ...value };
    const valueKey = Object.values(value);
    if (valueKey[0] == "" || valueKey[0] == null) {
      delete values[Object.keys(value)[0] as keyof ParamFilters];
    }
    dispatch(setParams(values));
  };

  useEffect(() => {
    dispatch(getPayments(paymerId));
  }, [filters]);

  const textInput = useRef<typeof TextField>(null);
  let timeout: NodeJS.Timeout;

  return (
    <Grid sx={{ flexGrow: 1, mt: "30px" }} container>
      <Grid item xs={12} md={3} lg={3} sx={{ pl: "10px" }}>
        <Box sx={{ mb: "10px" }}>ID Transacción</Box>
        <Box className="TextField-without-border-radius Text-select-field">
          <TextField
            inputRef={textInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              clearInterval(timeout);
              timeout = setInterval(() => {
                changeParams({
                  with_integration_transaction_id: event.target.value,
                });
                clearInterval(timeout);
              }, 500);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: "100%", borderRadius: "14px" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
        <Box sx={{ mb: "10px" }}>Fecha Inicial</Box>
        <Box className="TextField-without-border-radius Text-select-field">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={
                filters.with_created_after == null
                  ? null
                  : dayjs(filters.with_created_after)
              }
              onChange={(value: Dayjs | null) =>
                changeParams({
                  with_created_after:
                    value != null ? value.toISOString() : null,
                })
              }
              format="yyyy-MM-dd"
              slotProps={{
                textField: {
                  inputProps: {
                    placeholder: "De",
                  },
                  sx: {
                    width: "100%",
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ pl: "10px" }}>
        <Box sx={{ mb: "10px" }}>Fecha Final</Box>
        <Box className="TextField-without-border-radius Text-select-field">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={
                filters.with_created_before == null
                  ? null
                  : dayjs(filters.with_created_before)
              }
              onChange={(value: Dayjs | null) =>
                changeParams({
                  with_created_before:
                    value != null ? value.toISOString() : null,
                })
              }
              format="yyyy-MM-dd"
              slotProps={{
                textField: {
                  inputProps: {
                    placeholder: "Hasta",
                  },
                  sx: {
                    width: "100%",
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12} md={1} lg={1} sx={{ pl: "10px" }}>
        <Box className="Button-clean">
          <Button
            className="Clean-filters"
            color="primary"
            size="small"
            sx={{ mt: "0rem" }}
            onClick={() => {
              dispatch(setParams({}));
              //@ts-ignore
              textInput.current.value = "";
              dispatch(getPayments(paymerId));
            }}
          >
            <Box className="Clean-filters-text">Limpiar todo</Box>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterSelect;

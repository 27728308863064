import { store } from "../store/store";
import { Provider } from "react-redux";
import {
  ThemeConfig, 
  PrivateRoute, 
} from "@enerbit/base";  
import SectionPayments from "../pages/payment/components/SectionPayments"; 
import { PropsPayments } from "../models/paymentState";

export default function Routers ({paymerId}:PropsPayments) {
  return <Provider store={store}>
  <ThemeConfig>
    <PrivateRoute>
      <SectionPayments paymerId={paymerId} />
    </PrivateRoute>
  </ThemeConfig>
</Provider>
} 
import {  
  Box,  
  formatterPeso, 
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  StatusChip,
  moment,
  CustomModal,
  LoadingButton,
  CustomAlert
} 
from "@enerbit/base";    
import { Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelPayment, 
  cancelPaymentId, 
  getDetailPaymentInvoices,
  hasErrorCancelPayment, 
  isLoadingCancel, 
  payments, 
  setCancelPaymentId, 
  setDetail, 
  setResetVars, 
  successCancelPayment 
} from "../../../../features/payment/payment";
import { getPaymentsMethod, getTranslatePayments, getTypeStatusPayments } from "../../../../helpers/helpersPayments";
import { PropsPayments, Payments } from "../../../../models/paymentState";  
import { AppDispatch } from "../../../../store/store";
import DetailForm from "./DetailForm";

const TableInvoice = ({paymerId}: PropsPayments) => 
{     
  const items = useSelector(payments);
  const paymentId = useSelector(cancelPaymentId);
  const isLoading = useSelector(isLoadingCancel);
  const errorCancel = useSelector(hasErrorCancelPayment);
  const successCancel = useSelector(successCancelPayment);
  const dispatch = useDispatch<AppDispatch>();   
  const detailPayment = useRef<typeof CustomModal>(null);

  const callDetailPayment = (row: Payments) => { 
    dispatch(setDetail(row.id));
    dispatch(getDetailPaymentInvoices(row.id));
    //@ts-ignore
    detailPayment.current.changeModal(); 
  }; 

  return(
    <Fragment>
      <DetailForm ref={detailPayment} />
      { (errorCancel != "" || successCancel != "") && (
        <CustomAlert 
          onClose={() => dispatch(setResetVars())}
          sx={{ width: "100%", mb: "20px" }}
          severity={ errorCancel ? "error" : "success" }
          text={ errorCancel ? errorCancel : successCancel
          }
        />
      )}
      <TableContainer component={Paper} sx={{boxShadow: "none"}}>
        <Table className="Table-invoices" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID transacción</TableCell>
              <TableCell align="left">Fecha de pago</TableCell>
              <TableCell align="left">Método de pago</TableCell>
              <TableCell align="left">Total pagado</TableCell>
              <TableCell align="left">Estado</TableCell>
              <TableCell></TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            { items && items.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left"> { row.integration_transaction_id } </TableCell>
                <TableCell align="left">{ moment.parseZone(row.created_at).local().format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                <TableCell align="left">{ getPaymentsMethod(row.payment_method) }</TableCell>
                <TableCell align="left" sx={{fontWeight: "bold"}}>{ formatterPeso.format(row.value) }</TableCell>
                <TableCell align="left">
                  <StatusChip label={<Box>{getTranslatePayments(row.latest_state)}</Box>} type={getTypeStatusPayments(row.latest_state)} />
                </TableCell>
                <TableCell align="left">
                  {
                    (row.latest_state == "Created" || row.latest_state == "Pending") 
                    && 
                      row.payment_method == "bancolombia_collect"
                    &&
                      <LoadingButton
                        variant="outlined"
                        color="error"
                        size="small"
                        className="Loading-button Button-xs" 
                        sx={{padding: "6px !important", mr: "0.625rem"}}
                        loading={isLoading && row.id == paymentId}
                        onClick={() => {
                          dispatch(setCancelPaymentId(row.id));
                          dispatch(cancelPayment({id: row.id, paymerId: paymerId}))
                        }}
                      >
                        <Box style={{fontWeight: "bold", margin: 0, color: "#fb4237"}}>Cancelar</Box>
                      </LoadingButton>
                  }
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className="Loading-button Button-xs" 
                    sx={{padding: "6px !important"}}
                    onClick={() => callDetailPayment(row)}
                  >
                    <Box 
                    style={{fontWeight: "bold", margin: 0}}>Ver detalle</Box>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment> 
  ) 
};

export default TableInvoice; 
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentState, RootObject, ParamFilters, Document, Invoice, CancelPayment, DetailPaymentInvoices } from "../../models/paymentState";
import { StateStorage } from "../../models/stateStorage";
import { api } from "@enerbit/base"; 

const initialState: PaymentState = {
  page: 0,
  count: 10,
  isLoading: false,
  isLoadingDetail: true,
  paymentSelect: "", 
  payments: [],
  params: {},
  isLoadingCancel: false,
  cancelPaymentId: "",
  hasErrorCancelPayment: "",
  successCancelPayment: "",
  detailPayment: {}
};

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async (payerId: string, thunkAPI:any ) => { 
    const state = thunkAPI.getState() as StateStorage; 
    const res = await api.get("/payments/transactions/", { 
      params: {  
        page: state.paymentState.page, 
        size: 5,
        with_payer_id: payerId,
        ...state.paymentState.params,
      } 
    }); 
    return res.data;
  }
);  

export const getDetailPaymentInvoices = createAsyncThunk(
  "payments/transactions",
  async (id: string, thunkAPI:any ) => {  
    const detailPayment = await api.get(`/payments/transactions/${id}/explain`); 
    return detailPayment.data;
    }  
);  


export const cancelPayment = createAsyncThunk(
  "payments/cancelPayment",
  async (data: CancelPayment, thunkAPI:any ) => {  
    await api.post(`/payments/transactions/${data.id}/cancel`); 
    setTimeout(async () => {
      await thunkAPI.dispatch(getPayments(data.paymerId)); 
    }, 3000)
  }
);  

export const paymentSlice = createSlice({
  name: "paymentState",
  initialState,
  reducers: { 
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setParams: (state, action: PayloadAction<ParamFilters>) => {  
      state.params = action.payload;  
    },
    setDetail: (state, action: PayloadAction<string>) => {   
      state.paymentSelect = action.payload;  
    },
    setCancelPaymentId: (state, action: PayloadAction<string>) => {   
      state.cancelPaymentId = action.payload;  
    },
    setResetVars: (state) => {   
      state.hasErrorCancelPayment = "";  
      state.successCancelPayment  = "";  
      state.isLoadingCancel = false;
    },
  },
  extraReducers: (builder) => {
    builder 
    .addCase(getDetailPaymentInvoices.pending.type, (state) => {
      state.isLoadingDetail = true;
    })
    .addCase(getDetailPaymentInvoices.rejected.type, (state) => {
      state.isLoadingDetail = false; 
      state.detailPayment = {};
    })
    .addCase(getDetailPaymentInvoices.fulfilled.type, (state, action: PayloadAction<DetailPaymentInvoices>) => {  
      state.detailPayment = action.payload;
      state.isLoadingDetail = false; 
    })
    .addCase(cancelPayment.pending.type, (state) => {
      state.isLoadingCancel = true;
    })
    .addCase(cancelPayment.rejected.type, (state) => {
      state.isLoadingCancel = false; 
      state.cancelPaymentId = "";
      state.hasErrorCancelPayment = "Ocurrió un error al cancelar el pago";
    })
    .addCase(cancelPayment.fulfilled.type, (state) => {  
      state.isLoadingCancel = false; 
      state.cancelPaymentId = "";
      state.successCancelPayment = "Pago cancelado exitosamente";
      state.isLoading = true; 
    })
    .addCase(getPayments.pending.type, (state) => {
      state.isLoading = true;
    })
    .addCase(getPayments.rejected.type, (state) => {
      state.isLoading = false;
      state.count = 0;
      state.payments = [];
    })
    .addCase(getPayments.fulfilled.type, (state, action: PayloadAction<RootObject>) => {
      state.count = action.payload.pages; 
      state.payments = action.payload.items;
      state.isLoading = false; 
    })
  },
});

export const { handlePageChange, setParams, setDetail, setResetVars, setCancelPaymentId } = paymentSlice.actions;
  
export const page = (state: StateStorage) => state.paymentState.page;   
export const count = (state: StateStorage) => state.paymentState.count;  
export const isLoading = (state: StateStorage) => state.paymentState.isLoading;  
export const isLoadingDetail = (state: StateStorage) => state.paymentState.isLoadingDetail;  
export const payments = (state: StateStorage) => state.paymentState.payments;  
export const params = (state: StateStorage) => state.paymentState.params;   
export const detailPayment = (state: StateStorage) => state.paymentState.detailPayment;    
export const paymentSelect = (state: StateStorage) => state.paymentState.paymentSelect;  
export const isLoadingCancel = (state: StateStorage) => state.paymentState.isLoadingCancel;  
export const successCancelPayment = (state: StateStorage) => state.paymentState.successCancelPayment;  
export const hasErrorCancelPayment = (state: StateStorage) => state.paymentState.hasErrorCancelPayment;  
export const cancelPaymentId = (state: StateStorage) => state.paymentState.cancelPaymentId;  

export default paymentSlice.reducer;


import { PropsPayments } from "./models/paymentState";
import Routers from "./router/Routers"; 
import { initEnviroment } from "@enerbit/base";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL
});

export default function Root({paymerId}:PropsPayments) {  
  return  <Routers paymerId={paymerId} />  
}

import {
  Pagination,  
  Box,  
  CircularProgress
} from "@enerbit/base";
import { ChangeEvent } from "react";   
import "../../../assets/css/payment.scss";
import FilterSelect from "./reusable/FiltersSelect"; 
import { PropsPayments } from "../../../models/paymentState";
import TablePayments from "./reusable/TablePayments";
import { useDispatch, useSelector } from "react-redux";
import { count, getPayments, handlePageChange, isLoading, page } from "../../../features/payment/payment";
import { AppDispatch } from "../../../store/store";

const SectionPayments = ({paymerId}:PropsPayments) => 
{  
  const dispatch = useDispatch<AppDispatch>();   
  
  const countPayments = useSelector(count);
  const pagePayments = useSelector(page);
  const loading = useSelector(isLoading);

  return (
    <Box sx={{padding: "0px"}}> 
      <FilterSelect paymerId={paymerId}/> 
      <Box className="No-padding">
        {
          loading ?
            <Box className="Loading-flex">
              <CircularProgress />
            </Box>
          : ( 
            <TablePayments paymerId={paymerId} />
          )
        } 
        <Box className="Pagination-invoices">
          <Pagination 
            count={countPayments} 
            shape="rounded" 
            page={pagePayments+1} 
            onChange={(event: ChangeEvent<unknown>, value: number) => { 
              dispatch(handlePageChange(value-1));
              dispatch(getPayments(paymerId))
            }
          } 
          />  
        </Box>
      </Box> 
    </Box>
  );
};

export default SectionPayments;
